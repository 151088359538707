import { MainSubStructureModel, NormalProgramme, RenderByPermission, request } from '../../utils';
import type { BaseData } from '../../utils';
import { message, Button, Modal, Space } from 'antd';
import React from 'react';
import DetailModel from './compents/detail/model';
import AddModel from './compents/add/model';
import { action, observable, runInAction, toJS } from 'mobx';
import moment from 'dayjs';

export default class CommunityModel {
  constructor() {
    this.detailModel = new DetailModel(this);
    this.addModel = new AddModel(this);
    this.getCityList();
    this.getCategoryFirst();
    this.getCategorySecondDirect();
    this.getUserList();
    this.mainSubStructureModel.gridModel.onQuery();
  }

  public detailModel: DetailModel;

  public addModel: AddModel;

  @observable public cityList = [];

  @observable public firsList = [];

  @observable public secondList = [];

  @observable public priceData = new Array(3).fill({});

  @observable public priceOpen = false;

  @observable public priceTemplateList = [];

  @observable public priceTemplateId: string;

  @observable public priceTemplateOpen = false;

  @action
  public getPriceTemplateList = async (params: Record<string, any>) => {
    const req = await request<BaseData<{ list: any[]; total: number }>>({
      method: 'POST',
      url: '/quan/biz/price/template/list/page',
      data: {
        ...params,
        pageNum: 1,
        pageSize: 1000,
        showType: 1,
      },
    });
    this.priceTemplateList = req?.data?.list || [];
  };

  @action
  public setPriceTemplateOpen = () => {
    this.priceTemplateOpen = !this.priceTemplateOpen;
    if (!this.priceTemplateOpen) {
      this.priceTemplateId = undefined;
    }
  };

  @action
  public setPriceTemplateId = (id: string) => {
    this.priceTemplateId = id;
  };

  @action
  public setPriceOpen = () => {
    this.priceOpen = !this.priceOpen;
    if (this.priceData) {
      this.priceData = this.addModel.detailList.length ? this.addModel.detailList : new Array(3).fill({});
    }
    if (!this.priceOpen) {
      this.priceData = new Array(3).fill({});
    }
  };

  @action
  public setPriceData = (type: 0 | 1, index?: number) => {
    if (type == 0) {
      this.priceData = [...this.priceData, {}];
      return;
    }
    this.priceData = this.priceData.filter((item, i) => index !== i);
  };

  @action
  public setPrice = (value: any, i: number) => {
    this.priceData = this.priceData.map((item, index) => {
      if (i === index) {
        return {
          ...item,
          ...value,
        };
      }
      return { ...item };
    });
  };

  @action
  public onPriceTemplateSave = () => {
    if (!this.priceTemplateId) {
      message.warning('请选择价格模版');
      return;
    }
    this.addModel.priceId = this.priceTemplateId;
    this.addModel.detailList = toJS(this.priceTemplateList).filter((item, i) => item.id === this.priceTemplateId)[0]?.detailList;
    this.setPriceTemplateOpen();
  };

  @action
  public onSave = async () => {
    let verify = false;
    this.priceData.forEach((item: any) => {
      if (!item.price?.toString() || !item.priceCategory || !item.duration || !item.title) {
        verify = true;
      }
    });
    if (verify) {
      message.warning('请填写完整价格');
      return;
    }
    const { secondCategoryId, firstCategoryId, cityId } = this.addModel.formRef.current.getFieldsValue();
    const [parentRegionId, regionId] = cityId;

    const req = await request<{ data: string }>({
      method: 'POST',
      url: '/quan/biz/price/template/add',
      data: {
        showType: 0,
        detailList: this.priceData,
        categoryId: secondCategoryId,
        parentCategoryId: secondCategoryId,
        parentRegionId,
        regionId,
      },
    });
    this.addModel.priceId = req.data;
    this.addModel.detailList = this.priceData;
    this.setPriceOpen();
  };

  /**
   * 获取城市
   */
  @action
  public getCityList = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/sys_area/province/city/list',
    });
    const cityList = (req?.data || [])?.map((item) => ({
      label: item.name,
      value: item.id,
      children: item.childrenList.map((child) => ({
        label: child.name,
        value: child.id,
      })),
    }));
    this.cityList = cityList || [];
    this.normalProgramme.filterItems.addDict({ cityId: cityList });
  };

  @observable public userList = [];

  /**
   * 获取管理员
   */
  @action
  public getUserList = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/sys/user/list/all',
    });
    this.userList = this.toOptions(req?.data, 'nickName', 'id');
    this.normalProgramme.filterItems.addDict({ managerId: this.userList });
  };

  /**
   * 获取一级类别
   */
  @action
  public getCategoryFirst = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/biz/category/list/type',
      params: { categoryType: 0 },
    });
    this.firsList = this.toOptions(req?.data);
    this.normalProgramme.filterItems.addDict({ firstCategoryId: this.firsList });
  };

  /**
   * 获取二级类别
   */
  @action
  public getCategorySecondDirect = async () => {
    const req = await request<{ data: any[] }>({
      method: 'GET',
      url: '/quan/biz/category/list/type',
      params: { categoryType: 1 },
    });
    this.normalProgramme.filterItems.addDict({ secondCategoryId: this.toOptions(req?.data) });
  };

  /**
   * 根据一级类别获取二级类别
   * @param value
   */
  @action
  public getCategorySecond = async (value: string) => {
    const req = await request<{ data: any[] }>({
      method: 'POST',
      url: '/quan/biz/category/parent',
      data: { ids: `${value}` },
    });
    this.secondList = this.toOptions(req?.data);
  };

  public toOptions = (data: any[], label?: string, value?: string): Array<{ label: string; value: string }> =>
    (data || [])?.map((item: any) => ({
      label: item[label || 'categoryName'],
      value: `${item[value || 'id']}`,
    }));

  public normalProgramme = new NormalProgramme({
    filterItems: [
      {
        type: 'input',
        field: 'groupName',
        label: '社群名称',
      },
      {
        type: 'dateRange',
        field: 'startTime',
        label: '创建时间',
      },
      {
        type: 'cascader',
        field: 'cityId',
        label: '区域',
      },
      {
        type: 'select',
        field: 'firstCategoryId',
        label: '一级类别',
      },
      {
        type: 'select',
        field: 'secondCategoryId',
        label: '二级类别',
      },
      {
        type: 'select',
        field: 'managerId',
        label: '管理员',
      },
    ],
    handleSearch: () => this.mainSubStructureModel.gridModel.onQuery(),
  });

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [],
    grid: {
      columns: [
        {
          key: 'billingOrderNo',
          name: '操作',
          width: 170,
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');

            return (
              <Space>
                <RenderByPermission permissionId={`${pageId}_21`}>
                  <a
                    onClick={() => {
                      this.detailModel.onDetail(row.id);
                    }}
                  >
                    详情
                  </a>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <a
                    onClick={() => {
                      this.addModel.onEdit(row.id);
                    }}
                  >
                    编辑
                  </a>
                </RenderByPermission>
                <RenderByPermission permissionId={`${pageId}_4`}>
                  <a
                    onClick={() => {
                      Modal.confirm({
                        title: '提示',
                        content: '确认删除该社群？',
                        onOk: () =>
                          request({
                            method: 'POST',
                            url: '/quan/biz_social_group/manage/group/delete',
                            data: { id: row.id },
                          }).then(() => {
                            message.success('删除成功');
                            this.mainSubStructureModel.gridModel.onQuery();
                          }),
                      });
                    }}
                  >
                    删除
                  </a>
                </RenderByPermission>
              </Space>
            );
          },
        },
        {
          key: 'groupName',
          name: '社群名称',
          width: 200,
        },
        {
          key: 'userCount',
          name: '群人数',
          formatter: ({ row }) => <div>{row.userCount}个</div>,
        },
        {
          key: 'priceList',
          name: '价格',
          formatter: ({ row }) => <div>{row?.priceList?.join(' / ') || 0}元</div>,
        },
        {
          key: 'orderCount',
          name: '订单量',
          formatter: ({ row }) => <div>{row.orderCount}个</div>,
        },
        {
          key: 'cityName',
          name: '区域',
        },
        {
          key: 'firstCategoryName',
          name: '类别',
          formatter: ({ row }) => (
            <div>
              {row.firstCategoryName} / {row.secondCategoryName}
            </div>
          ),
        },
        {
          key: 'managerName',
          name: '管理员',
        },
        {
          key: 'createTime',
          name: '创建时间',
          formatter: ({ row }) => <div>{row?.createTime ? moment(row?.createTime).format('YYYY-MM-DD HH:mm:ss') : ''}</div>,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const postParams = {
          ...params,
          filterParams: undefined,
        };
        const filterParams = this.normalProgramme.filterItems.params;
        if (filterParams?.startTime) {
          const [startTime, endTime] = filterParams.startTime.split(',');
          filterParams.startTime = moment(startTime).format();
          filterParams.endTime = moment(endTime).format();
        }
        if (filterParams?.cityId) {
          const [provinceId, cityId] = filterParams.cityId.split(',');
          filterParams.cityId = cityId;
        }
        return request<BaseData<any>>({
          url: '/quan/biz_social_group/manage/group/list',
          method: 'POST',
          data: {
            ...postParams,
            ...filterParams,
          },
        });
      },
    },
  });
}
